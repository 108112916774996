
const AlertMessage = (props) => {

  let errorMessage = "";

  if (props.alertMessage.hasOwnProperty("message") &&
    props.alertMessage.message.length > 0) {
    errorMessage = props.alertMessage.message;
  } else {
    errorMessage = "Please Contact to support. Thank You"
  }

  return (
    <>
      <div>
        {errorMessage && (
          <>
            <div id="terminateDiv" style={{ position: "relative", width: "100%", height: "100vh" }}>
              <h1 style={{textAlign: "center", paddingTop: "150px"}}>Sorry, Something went wrong</h1>
            </div>
            <div id="rejectDiv" style={{ position: "relative", width: "100%", height: "100vh" }}>
              <h1 style={{textAlign: "center",paddingTop: "150px"}}>Sorry, Something went wrong</h1>
            </div>
          </>)}
      </div>
    </>

  )
}

export default AlertMessage;