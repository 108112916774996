import {callAPI} from "./apiWrapper";


export const updateParticipantFromClient = (queryParamas, landingURL)=>{
    let allqueryParmas = queryParamas + "&landingURL="+encodeURIComponent(landingURL);
 
    allqueryParmas = allqueryParmas+"&userStatus=1"

    return callAPI("GET", "updateParticipantFromClient/?", allqueryParmas, {}).then((result) => {
		  return result;
    });
}

